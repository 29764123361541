* {
  font-family: Arial, Helvetica, sans-serif !important;
}

h2 {
  font-size: 24px;
  font-style: normal;
}

h3 {
  font-size: 20px;
  font-style: normal;
}

h4 {
  font-size: 18px;
  font-style: normal;
}

h5 {
  font-size: 16px;
  font-style: normal;
}

p {
  font-size: 16px;
  margin: 0;
}